/* @import url('https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=David+Libre:wght@400;500;700&display=swap');
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=b8c1a4f3-be7c-48f2-9de7-5925d3706d74&fontids=5190107,5195713"); */

@font-face{
    font-family: "David Hadash Formal W05 Bold";
    src:url("./assets/2d154832-4e02-4e0f-8b9c-1f94d2395d03.woff2") format("woff2"), url("./assets/5b08de84-b769-4615-9114-b4e2e19ea468.woff") format("woff");
    font-display: swap;
}
@font-face{
    font-family: "David Hadash Formal W05 Regular";
    src:url("./assets/b3d20a2d-ead1-434c-a795-f4ac01413b9a.woff2") format("woff2"),url("./assets/d61e0399-afed-47b3-ac19-6eb689aae9df.woff") format("woff");
    font-display: swap;
}
@font-face {
  font-family: "Alef Hebrew";
  src: url(./assets/ALEF-REGULAR.TTF);
  font-display: swap;
}

@font-face {
  font-family: "Alef Bold Hebrew";
  src: url(./assets/ALEF-BOLD.TTF);
  font-display: swap;
}

@font-face {
  font-family: "David Regular";
  src: url(./assets/David-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: "David Bold";
  src: url(./assets/David-Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: "Adama Book";
  src: url(./assets/FbAdamaBook-Light.otf);
  font-display: swap;
}

@font-face {
  font-family: "Adama Book Bold";
  src: url(./assets/FbAdamaBook-Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: "FbVayikra";
  src: url(./assets/FbVayikra-Regular.otf);
  font-display: swap;
}

strong {
  font-family: "Adama Book Bold", "David Hadash Formal W05 Bold", "Alef Bold Hebrew", Arial, Helvetica, sans-serif !important;
}

body {
  margin: 0 !important;
  position: relative;
  background-color: rgb(247, 247, 247);
  font-family: "Alef Hebrew", "Alef", Arial, Helvetica, sans-serif !important;
}

.MuiTypography-h5, .MuiTableCell-head, .MuiTableCell-root, .MuiButtonBase-root, .MuiFormLabel-root, option, select {
  font-family: "Alef Hebrew", "Alef", Arial, Helvetica, sans-serif !important;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #8194f8;
  border-radius: 5px;
}

.MuiTypography-body1 {
  font-family: "Alef Hebrew" !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.container {
  min-height: 82vh;
}


.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.rtl .MuiInputLabel-outlined, .rtl .MuiInputLabel-formControl {
  /* transform: translate(-14px, 20px) scale(1); */
  transform-origin: top right;
  right: 0 !important;
  left: unset;
}


.ltr .MuiInputLabel-outlined, .ltr .MuiInputLabel-formControl {
  transform: translate(14px, 20px) scale(1);
  transform-origin: top left;
  left: 0 !important;
  right: unset;
}

.rtl .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-14px, -6px) scale(0.75)
}

.ltr .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(16px, -6px) scale(0.75)
}

.ltr .MuiInputBase-root legend {
  text-align: end;
}

.form-control {
  margin: 10px !important;
  width: 67ch;
}

@media screen and (max-width:780px) {
  .form-control {
    width: 100%;
  }
}

.donate-btn {
  min-width: unset !important;
  height: 2.5rem;
  width: 2.5rem;
  padding-top: 10px !important;
  background-color: #9dcdfa !important;
  position: fixed !important;
  bottom: 5rem;
  left: 0%;
  z-index: 1000;
  padding: 5px 0 0 !important;
}

.donate-btn:hover {
  background-color: #7bbfff !important;
}

.donate-btn a {
  padding: 6px 8px;
}

.modal-donate-body {
  top: 10% !important;
}

.spinner-wrapper, .modal-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #2c2c2c41;
  z-index: 1200000;
}

.loader-inner {
  display: inline-block;
  width: 100%;
  padding-top: 30px !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  min-height: 50px;
}


.modal-body {
  display: inline-block;
  padding: 20px 30px;
  background-color: #ffffff;
  border: 2px solid #011746;
  color: #011746;
  border-radius: 5px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  min-width: 320px;
  min-height: 500px;
}

.ad {
  max-width: min(500px, 90%);
  border: 3px solid #fff;
}

.ad-modal-wrapper {
  background-color: #000c26f6 !important;
}

.ad-modal-body {
  max-width: 500px;
  background-color: transparent !important;
  border: unset !important;
  padding: unset !important;
}

.close-ad {
  color: white;
  font-size: 40px !important;
  right: 0px !important;
  top: -27px !important;
}
.MuiContainer-root.inline-grid {
  display: inline-grid;
}


.accordion {
  background-color: #303f9f !important;
  color: #fff !important;
}

.accordion div:first-child {
  margin: 0;
}

.accordion h4 {
  margin: 2px !important;
}


.formControl {
  max-width: 67ch;
  margin: 10px;
}
@media screen and (min-width: 1200px) { 

  div.container {
  
    max-width: 1280px;
  }
}

@media screen and (max-width: 1280px) {
  .donate-btn {
    bottom: 8.5rem;
  }
}

@media screen and (max-width: 450px) {
  .donate-btn {
    bottom: 11.5rem;
  }
}
