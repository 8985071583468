footer {
  padding: 15px 0 10px ;
  text-align: center;
  background-color: #011746;
}

.web-layout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-layout div:first-child {
  margin-left: 50px;
}

.mobile-layout {
  display: block;
}

.mobile-layout div:first-child  {
  margin-bottom: 10px;
}

footer svg {
  margin: 0 5px;
}

footer a {
  color: #fffaf0;
  margin: 0 5px;
}

.phone-icon {
  font-size: 22px !important;
}

.listen-on-phone {
  font-size: 25px !important;
  color: rgb(56, 241, 19);
}

.apple-icon {
  font-size: 28px !important;
  margin-bottom: 5px !important; 
}

.MuiTooltip-tooltipPlacementTop {
  margin: 0 !important;
}

.MuiTooltip-tooltip {
  margin-bottom: 4px !important;
  background-color: #011746 !important;
  border: 1px white solid;
}