.nav-bar {
  background-image: linear-gradient(
    to right,
    #011746 90%,
    #01174600 91.5%
  ) !important;
  margin: 0 !important;
  position: relative;
  padding-right: 10% !important;
  height: 80px !important;
  z-index: 1;
}

.ltr .nav-bar {
  background-image: linear-gradient(
    to left,
    #011746 90%,
    #01174600 91.5%
  ) !important;
  padding-right: initial !important;
  padding-left: 10% !important;
}

.toolbar {
  height: 80px !important;
}

.bsd {
  font-size: 14px !important;
  top: 7px;
  right: 25px;
  position: absolute;
}

.ltr .bsd {
  left: 25px;
  font-size: 12px !important;
}

.MuiAppBar-positionStatic {
  position: relative !important;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}

.img-top {
  height: 80px;
  width: 10%;
  display: inline-block;
  background: url(../../assets/rabitop.webp) no-repeat center center / cover;
  position: absolute;
  border-right: #011746 solid 3px;
  right: 0;
  top: 0;
}

.ltr .img-top {
  border-left: #011746 solid 3px;
  border-right: unset;
  left: 0;
  right: unset;
}

.link {
  text-decoration: none;
  color: #ffffff !important;
  cursor: pointer;
  font-family: "Alef Hebrew", "Alef", Arial, Helvetica, sans-serif !important;
}

.lang-btn-main {
  color: #ffffff !important;
  border-color: #ffffff !important;
  margin-right: 20px !important;
}

.ltr .lang-btn-main {
  margin-right: initial !important;
  margin-left: 20px !important;
}

.notification-btn {
  color: #ffffff !important;
  font-size: 100px !important;
}

.alert-dot {
  position: absolute;
  top: -12px;
  right: 14px;
  font-size: 45px;
  color: red;
}

.bar-link:hover {
  border-bottom: #2282f0 solid 2px !important;
  border-top: transparent solid 2px !important;
}

.dnld-icon {
  padding: 0 0 5px 2px;
  font-size: 1.2rem !important;
  box-sizing: content-box;
}

.nav-bar .MuiIconButton-root {
  font-size: 2.5rem !important;
}

.bar-link {
  margin: 0 10px !important;
}

.topic-link {
  color: #1a1a1a !important;
  width: 100%;
  text-align: right;
}

.search {
  border: 2px solid #008080 !important;
  color: #008080 !important;
}

.makeStyles-searchIcon-5 {
  color: #008080 !important;
}

.MuiList-root {
  margin-right: 0% !important;
}

.MuiDrawer-paper {
  top: 80px !important;
  height: 85% !important;
  background-color: #e7efff !important;
  left: 0;
  right: unset !important;
}

.rtl .MuiDrawer-paper {
  left: 0;
  right: unset !important;
}

.ltr .MuiDrawer-paper {
  right: 0 !important;
  left: unset !important;
}

.MuiDrawer-paper .MuiListItem-root {
  justify-content: center !important;
}

.MuiDrawer-paper .bar-link {
  width: 100%;
  font-size: 1.2rem !important;
}

.MuiDrawer-paper .bar-link:hover {
  border: none !important;
}

.MuiDrawer-paper .link {
  width: 100%;
  text-align: center !important;
  color: #011746 !important;
}

.MuiDrawer-paper a {
  display: inline-block;
  width: 100%;
}

.MuiDrawer-paper .MuiMenuItem-root {
  white-space: pre-wrap;
}

.topic-list {
  max-height: 25vh !important;
  overflow-y: scroll !important;
}

@media (max-width: 1335px) {
  .lang-btn-main, .ltr .lang-btn-main {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}

@media (max-width: 960px) {
  .nav-bar {
    background-image: linear-gradient(
      to right,
      #011746 85%,
      #01174600 86.5%
    ) !important;
    padding-right: 13% !important;
  }
  .ltr .nav-bar {
    background-image: linear-gradient(
      to left,
      #011746 85%,
      #01174600 86.5%
    ) !important;
    padding-right: initial !important;
    padding-left: 13% !important;
  }

  .img-top {
    height: 80px;
    width: 15%;
    background: url(../../assets/rabitop.webp) no-repeat center center / cover;
  }
  
}

@media (max-width: 530px) {
  .ltr .title {
    font-size: 25px;
  }

  .bsd {
    right: 18px;
  }

  .ltr .bsd {
    font-size: 10px !important;
    left: 18px;
  }
  
  .lang-btn-main, .ltr .lang-btn-main {
    margin-right: 0 !important;
    margin-left: 0 !important;
    min-width: 0 !important;
    border: unset !important;
  }
}

@media (max-width: 430px) {
  .nav-bar {
    background-image: linear-gradient(
      to right,
      #011746 85%,
      #01174600 86.5%
    ) !important;
    padding-right: 13% !important;
    height: 70px !important;
  }

  .nav-bar .MuiTypography-h5 {
    margin: 0 !important;
  }

  .nav-bar .MuiIconButton-root {
    padding: 5px !important;
  }

  .img-top {
    height: 70px;
    width: 15%;
    background: url(../../assets/rabitop.webp) no-repeat center center / cover;
  }

  .title {
    font-size: 28px;
  }

  .ltr .title {
    font-size: 20px;
  }

  .bsd {
    right: 18px;
  }

  .ltr .bsd {
    font-size: 10px !important;
    left: 18px;
  }
}

@media (max-width: 355px) {

  .nav-bar .MuiTypography-h4{
    line-height: 20px !important;
  }

  .ltr .nav-bar .MuiTypography-h4 .bsd{
    line-height: 10px !important;
  }

  .bsd {
    right: 18px;
    top: 0;
  }

  .ltr .bsd {
    font-size: 8px !important;
    left: 16px;
    top: 7px;
  }
}
